<template>
  <div>
    <div>
      <breadcrumb :breadcrumb_link="direction_breadcrumb" />
    </div>

    <div class="header">
      <div class="headerimage" />
    </div>

    <div class="container contentDesc">
      <div class="title1">
        <h2>
          <strong>{{ $t('directions_title') }}</strong>
        </h2>
      </div>
    </div>

    <div align="center">
      <div class="row justify-content-center categoryRadioGroup">
        <div id="btn-radios-2" style="width: 100%">
          <label class="cateBoxContainer">
            <input type="radio" value="핀크스 리조트 안내" v-model="tab_name" />
            <div class="front-end cateBox">
              <span>{{ $t('directions_tab1_title') }}</span>
            </div>
          </label>
          <label class="cateBoxContainer">
            <input type="radio" value="리조트 내 이동" v-model="tab_name" />
            <div class="back-end cateBox">
              <span>{{ $t('directions_tab2_title') }}</span>
            </div>
          </label>
        </div>
      </div>
      <div class="my-3">
        <Pinxresortmoveinfo v-if="tab_name === '핀크스 리조트 안내'" />
        <Resortmoveinfo v-if="tab_name === '리조트 내 이동'" />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pinxresortmoveinfo from '@/pages/Footerlink/Components/Pinxresortmoveinfo';
import Resortmoveinfo from '@/pages/Footerlink/Components/Resortmoveinfo';

export default {
  name: 'Direction',
  data() {
    return {
      direction_breadcrumb: {
        toplink: 'HOME',
        prevlink: this.$t('breadcrumb'),
        present: this.$t('breadcrumb'),
      },
      tab_name: '핀크스 리조트 안내',
      isLang: 0,
    };
  },
  watch: {
    '$i18n.locale'(newVal, oldVal) {
      this.refreshLocale(newVal);
    },
  },
  created() {
    this.refreshLocale(this.$i18n.locale);
  },
  methods: {
    refreshLocale(newVal) {
      if (newVal === 'ko') {
        this.isLang = 0;
      } else if (newVal === 'en') {
        this.isLang = 1;
      } else if (newVal === 'ja') {
        this.isLang = 2;
      } else {
        this.isLang = 0;
      }

      this.direction_breadcrumb = {
        toplink: 'HOME',
        prevlink: this.$t('breadcrumb'),
        present: this.$t('breadcrumb'),
      };

      this.$store.dispatch('Display/changeName', {name: this.$t('breadcrumb')});
    },
  },
  mounted() {
    this.$store.dispatch('Display/changeName', {name: '오시는 길'});
  },
  components: {Breadcrumb, Pinxresortmoveinfo, Resortmoveinfo},
};
</script>

<style scoped lang="scss">
.menuSiteRadioGroup {
  height: 4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.categoryRadioGroup {
  display: flex;
  justify-content: center;
  width: 70%;
  height: 4rem;
  margin-top: 1rem;
  /*border-bottom: 1px solid #e0e0e0;*/
}
.cateBoxContainer {
  width: 50%;
}
.siteButton {
  height: 100%;
}
.customButton {
  font-size: 2rem;
}

input[type='radio'] {
  display: none;
  &:checked {
    + .siteBox {
      background-color: #343a40;
      span {
        color: white;
        //        transform: translateY(100%);
        &:before {
          //          transform: translateY(100%);
          opacity: 1;
        }
      }
    }
  }
}
input[type='radio'] {
  display: none;
  &:checked {
    + .cateBox {
      background-color: #333;
      span {
        color: white;
        //        transform: translateY(100%);
        &:before {
          //          transform: translateY(100%);
          opacity: 1;
        }
      }
    }
  }
}
.siteBox {
  background-color: white;
  border: 1px solid #b7b7b7;
  height: 4rem;
  text-align: center;
  cursor: pointer;
  position: relative;
  font-weight: 900;
  line-height: 3.6rem;
  span {
    font-size: 0.9rem;
    user-select: none;
    color: #2f2f2f;
    &:before {
      display: block;
      opacity: 0;
      font-weight: normal;
      color: white;
    }
  }
}
.cateBox {
  background-color: white;
  border: 1px solid #b7b7b7;
  //width: 14rem;

  height: 3rem;
  text-align: center;
  cursor: pointer;
  position: relative;
  font-weight: 900;
  line-height: 3rem;
  &:active {
  }
  span {
    font-size: 0.9rem;
    user-select: none;
    color: #2f2f2f;
    &:before {
      display: block;
      opacity: 0;
      font-weight: normal;
      color: white;
    }
  }
}
.front-end {
  width: 100%;
  span {
    &:before {
      content: '';
    }
  }
}
.back-end {
  width: 100%;
  span {
    &:before {
      content: '';
    }
  }
}
.header {
  position: relative;
  width: 100%;
  > img {
    width: 100%;
  }

  .overlay {
    color: White;
    position: absolute;
    text-align: center;
    z-index: 12;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: lighter;
    letter-spacing: -0.1rem;
  }
}

.contentDesc {
  text-align: center;

  .title1 {
    margin-top: 3rem;
    margin-bottom: 5rem;
  }

  .title2 {
    margin-top: 3rem;
  }

  .title3 {
    margin-top: 1rem;
    margin-bottom: 5rem;
  }
}

@media (min-width: 992px) {
  .headerimage {
    width: 100%;
    margin: 0;
    content: url('~@/assets/images/common/direction_headerimage.jpg');
  }
}
@media (max-width: 992px) {
  .headerimage {
  }
  .header {
    position: relative;
    width: 100%;
    padding-top: 5rem;
    > img {
      width: 100%;
    }

    .overlay {
      padding-top: 5rem;
      color: White;
      position: absolute;
      text-align: center;
      z-index: 12;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: lighter;
      letter-spacing: -0.1rem;
    }
  }
}
</style>

<i18n>
{
  "ko": {
    "breadcrumb": "오시는 길",
    "directions_title": "오시는 길",
    "directions_tab1_title": "핀크스 리조트 안내",
    "directions_tab2_title": "리조트 내 이동"
  }
,"en": {
  "breadcrumb": "Directions",
  "directions_title": "Directions",
  "directions_tab1_title": "Location",
  "directions_tab2_title": "Within Resort"
}
,"ja": {
  "breadcrumb": "アクセスマップ",
  "directions_title": "アクセスマップ",
  "directions_tab1_title": "アクセスマップ",
  "directions_tab2_title": "リゾート內の移動"
}
}
</i18n>
