<template>
  <div>
    <div class="map">
      <div class="container">
        <div class="row d-flex justify-content-center mt-5 mb-5">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3334.13300978989!2d126.38649395138047!3d33.315337980718795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x350c5bd3da65f3a5%3A0xe5073633be527e62!2z7ZWA7YGs7Iqk6rOo7ZSE7YG065-97ZqM7JuQ7KCc7YG065-97ZWY7Jqw7Iqk!5e0!3m2!1sko!2skr!4v1542268637093"
            width="1080"
            height="550"
            frameborder="0"
            style="border: 0"
            allowfullscreen=""
          ></iframe>
        </div>

        <div class="location-detail row d-flex justify-content-center">
          <ul class="location-list">
            <li class="mb-5">
              <p class="dir">
                <span class="spot start">
                  <strong v-html="$t('moveinfo_desc1')"></strong>
                </span>
                <span class="spot end">
                  <strong>{{ $t('moveinfo_desc3') }}</strong>
                </span>
              </p>
              <p class="duration">{{ $t('moveinfo_desc2') }}</p>
            </li>
            <li class="mb-5">
              <p class="dir">
                <span class="spot start"><strong v-html="$t('moveinfo_desc4')"></strong></span>
                <span class="spot end">
                  <strong>{{ $t('moveinfo_desc6') }}</strong>
                </span>
              </p>
              <p class="duration">{{ $t('moveinfo_desc5') }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pinxresortmoveinfo',
  data() {
    return {
      pinxresortmoveinfo_breadcrumb: {
        toplink: 'HOME',
        prevlink: '회사소개',
        present: '회사소개',
      },
    };
  },
};
</script>

<style lang="scss">
ol,
ul {
  list-style: none;
}
.location-list {
  color: #505050;
  margin: 0 auto;
  width: 560px;
  padding-left: 0;
}
.location-detail .location-list li {
  position: relative;
  background-image: url('~@/assets/images/common/dir_line.png');
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 0px;
}
.location-detail .location-list {
  margin: 0 auto;
  width: 560px;
}
.location-detail .location-list .dir {
  overflow: hidden;
}
.location-detail .location-list .spot {
  float: left;
  display: block;
  width: 75px;
  font-size: 14px;
  line-height: 1.1;
  text-align: center;
}
.location-detail .location-list .spot::before {
  content: ' ';
  display: block;
  margin: 0 auto;
  margin-bottom: 12px;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  background-color: #fff;
  border: 4px solid #8c705e;
  border-radius: 50%;
}
.location-detail .location-list .spot::before {
  border-color: #898989;
}
.location-detail .location-list .spot.end {
  float: right;
}
.location-detail .location-list .spot {
  float: left;
  display: block;
  width: 75px;
  font-size: 14px;
  line-height: 1.1;
  text-align: center;
}
.location-detail .location-list .spot::before {
  border-color: #898989;
}
.location-detail .location-list .spot::before {
  content: ' ';
  display: block;
  margin: 0 auto;
  margin-bottom: 12px;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  background-color: #fff;
  border: 4px solid #8c705e;
  border-radius: 50%;
}
.location-detail .location-list .duration {
  position: absolute;
  top: 36px;
  left: 0;
  width: 100%;
  font-size: 13px;
  text-align: center;
}
</style>

<i18n>
{
  "ko": {
    "moveinfo_desc1": "제주<br/>공항",
    "moveinfo_desc2": "차량으로 약 50분 소요",
    "moveinfo_desc3": "핀크스",
    "moveinfo_desc4": "중문<br/>관광단지",
    "moveinfo_desc5": "차량으로 약 20분 소요",
    "moveinfo_desc6": "핀크스"
  }
,"en": {
  "moveinfo_desc1": "Jeju Airport",
  "moveinfo_desc2": "50 minutes by car",
  "moveinfo_desc3": "PINX",
  "moveinfo_desc4": "Jungmun<br/>Resort",
  "moveinfo_desc5": "20 minutes by car",
  "moveinfo_desc6": "PINX"
}
,"ja": {
  "moveinfo_desc1": "ちぇじゅこくさいくうこう ",
  "moveinfo_desc2": "乗用車で50分",
  "moveinfo_desc3": "ピンクス",
  "moveinfo_desc4": "チュンムン・<br/>グァングァンダンジ ",
  "moveinfo_desc5": "乗用車で20分",
  "moveinfo_desc6": "ピンクス"
}
}
</i18n>
