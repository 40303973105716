<template>
  <div>
    <div class="container">
      <div class="d-flex justify-content-center mt-5 mb-5">
        <div class="map-resort">
          <img
            src="@/assets/images/common/map_resort.jpg"
            style="display: block; width: 100%; margin: 0"
            v-show="isLang === 0"
          />
          <img
            src="@/assets/images/common/map_resort_en.jpg"
            style="display: block; width: 100%; margin: 0"
            v-show="isLang === 1"
          />
          <img
            src="@/assets/images/common/map_resort_jp.jpg"
            style="display: block; width: 100%; margin: 0"
            v-show="isLang === 2"
          />
        </div>
      </div>

      <div class="location-detail row d-flex justify-content-center">
        <ul class="location-list">
          <li class="mb-5">
            <p class="dir">
              <span class="spot start">
                <strong>{{ $t('moveinfo_desc1') }}</strong>
              </span>
              <span class="spot end">
                <strong>{{ $t('moveinfo_desc3') }}</strong>
              </span>
            </p>
            <p class="duration">{{ $t('moveinfo_desc2') }}</p>
          </li>

          <li class="mb-5">
            <p class="dir">
              <span class="spot start">
                <strong>{{ $t('moveinfo_desc1') }}</strong>
              </span>
              <span class="spot end">
                <strong v-html="$t('moveinfo_desc5')"></strong>
              </span>
            </p>
            <p class="duration">{{ $t('moveinfo_desc4') }}</p>
          </li>

          <li class="mb-5">
            <p class="dir">
              <span class="spot start">
                <strong>{{ $t('moveinfo_desc1') }}</strong>
              </span>
              <span class="spot end">
                <strong v-html="$t('moveinfo_desc7')"></strong>
              </span>
            </p>
            <p class="duration">{{ $t('moveinfo_desc6') }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Resortmoveinfo',
  data() {
    return {
      resortmoveinfo_breadcrumb: {
        toplink: 'HOME',
        prevlink: '회사소개',
        present: '회사소개',
      },
    };
  },
  methods: {
    refreshLocale(newVal) {
      if (newVal === 'ko') {
        this.isLang = 0;
      } else if (newVal === 'en') {
        this.isLang = 1;
      } else if (newVal === 'ja') {
        this.isLang = 2;
      } else {
        this.isLang = 0;
      }
    },
  },
  watch: {
    '$i18n.locale'(newVal, oldVal) {
      this.refreshLocale(newVal);
    },
  },
  created() {
    this.refreshLocale(this.$i18n.locale);
  },
};
</script>

<style lang="scss">
.map-resort {
  border: 1px solid #959595;
}
ol,
ul {
  list-style: none;
}
.location-list {
  color: #505050;
  margin: 0 auto;
  width: 570px;
  padding-left: 0;
}
.location-detail .location-list li {
  position: relative;
  background-image: url('~@/assets/images/common/dir_line.png');
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 0px;
}
.location-detail .location-list {
  margin: 0 auto;
  width: 570px;
}
.location-detail .location-list .dir {
  overflow: hidden;
}
.location-detail .location-list .spot {
  float: left;
  display: block;
  width: 80px;
  font-size: 14px;
  line-height: 1.1;
  text-align: center;
}
.location-detail .location-list .spot::before {
  content: ' ';
  display: block;
  margin: 0 auto;
  margin-bottom: 12px;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  background-color: #fff;
  border: 4px solid #8c705e;
  border-radius: 50%;
}
.location-detail .location-list .spot::before {
  border-color: #898989;
}
.location-detail .location-list .spot.end {
  float: right;
}
.location-detail .location-list .spot {
  float: left;
  display: block;
  width: 85px;
  font-size: 14px;
  line-height: 1.1;
  text-align: center;
}
.location-detail .location-list .spot::before {
  border-color: #898989;
}
.location-detail .location-list .spot::before {
  content: ' ';
  display: block;
  margin: 0 auto;
  margin-bottom: 12px;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  background-color: #fff;
  border: 4px solid #8c705e;
  border-radius: 50%;
}
.location-detail .location-list .duration {
  position: absolute;
  top: 36px;
  left: 0;
  width: 100%;
  font-size: 13px;
  text-align: center;
}
</style>

<i18n>
{
  "ko": {
    "moveinfo_desc1": "골프클럽",
    "moveinfo_desc2": "차량으로 약 1분 소요",
    "moveinfo_desc3": "포도호텔",
    "moveinfo_desc4": "차량으로 5분 소요",
    "moveinfo_desc5": "디아넥스",
    "moveinfo_desc6": "차량으로 6분 소요",
    "moveinfo_desc7": "비오토피아<br/>레스토랑"
  }
,"en": {
  "moveinfo_desc1": "PINX Golf Club",
  "moveinfo_desc2": "1 minutes by car",
  "moveinfo_desc3": "PODO Hotel",
  "moveinfo_desc4": "5 minutes by car",
  "moveinfo_desc5": "THE ANNEX<br/>Hotel",
  "moveinfo_desc6": "6 minutes by car",
  "moveinfo_desc7": "BIOTOPIA<br/>RESTAURANT"
}
,"ja": {
  "moveinfo_desc1": "ピンクスゴルフクラブ",
  "moveinfo_desc2": "乗用車で1分",
  "moveinfo_desc3": "ポドホテル",
  "moveinfo_desc4": "乗用車で5分",
  "moveinfo_desc5": "ジ・アネックスホテル",
  "moveinfo_desc6": "乗用車で6分",
  "moveinfo_desc7": "ビオトピア<br/>レストラン"
}
}
</i18n>
